.menu-component-wrapper {
  height: 100%;

  &--item {
    cursor: pointer;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
