.step-component-content-wrapper {
  width: 100%;

  .headline {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin: 20px 0;
    color: #464646;

    @media (max-width: 480px) {
      font-size: 1.2em;
    }
  }

  .checkbox {
    @media (max-width: 480px) {
      font-size: 0.7em;
      padding-left: 10px;
    }
  }

  .radio {
    display: flex;
    flex-direction: column;
    align-items: center;

    .step-radio {
      cursor: pointer;
      box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
        0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
      border-radius: 20px;
      background: #f2f4f4;
      display: flex;
      padding: 15px;
      justify-content: center;
      margin: 10px 0;
      max-width: 600px;
      min-width: 200px;
      width: 100%;

      &.active {
        background: #bcbcbc;
      }
    }
  }

  .button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .step-button {
      cursor: pointer;
      margin: 10px;
      height: 220px;
      width: 200px;
      display: flex;
      flex-direction: column;
      background: #f2f4f4;
      border-radius: 20px;
      align-items: center;
      box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
        0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);

      @media (max-width: 480px) {
        height: 120px;
        width: 100px;
      }

      &.active {
        background: #bcbcbc;
      }

      &--icon {
        flex-shrink: 0;
        padding: 35px 0;

        @media (max-width: 480px) {
          padding: 10px 0px;
        }

        svg {
          height: 60px;

          @media (max-width: 480px) {
            height: 40px;
          }
        }
      }
      &--label {
        padding: 10px;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        word-break: keep-all;
        max-width: 20ch;
        text-align: center;
        font-size: 1.2em;

        @media (max-width: 480px) {
          word-break: break-all;
          font-size: 0.7em;
        }
      }
    }
  }

  .button.iFrame {
    flex-wrap: nowrap;
  }
}
