.success-component-wrapper {
  padding-top: 20px;
  text-align: center;

  .icon {
    margin-bottom: 40px;

    svg {
      height: 100px;
    }
  }

  .text {
    &--headline {
      margin-bottom: 20px;
      font-weight: bold;
    }

    &--content {
      margin: 0 auto;
      width: 100%;
      max-width: 400px;
    }
  }

  .link {
    margin-top: 40px;
    a {
      text-decoration: underline;
      color: black;
    }
  }
}
