.link-configuration-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &--headline {
    width: 100%;
    padding-left: 49px;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  &--content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;

    .menu {
      margin-right: 10px;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;

      .link-box-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      }

      .link-box {
        border-radius: 20px;
        background: #f2f4f4;
        margin: 10px;
        padding: 10px;
        cursor: pointer;
        box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
          0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
          0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
        height: 120px;
        width: 80%;
        display: flex;
        flex-direction: column;

        .link-position {
          font-weight: bold;
          margin: auto;
        }

        .link-description {
          font-weight: bold;
          text-align: center;
          margin: auto;
        }
      }

      &--link--add-container {
        margin-left: auto;
      }

      &--add {
        z-index: 1;
        height: 50px;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        padding: 10px;
        border-radius: 20px;
        margin: 20px;
        background: #f2f4f4;
        box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
          0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
          0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
        margin-left: auto;
        svg {
          cursor: pointer;
          height: 40px;
          width: 40px;
        }
      }

      &--link-information-wrapper {
        margin: 20px;
        height: fit-content;
        box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
          0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
          0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
        padding: 10px;
        border-radius: 20px;
        max-width: 400px;
        background: #f2f4f4;

        .link-information {
          &--headline {
            margin-bottom: 20px;
            text-align: center;
          }
          &--form {
            padding: 10px;
            .links-position-container-wrapper {
              padding: 5px;
            }
            .links-position-header {
              font-size: 1.3rem;
              padding: 0 20px;
            }
            .links-position-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 20px;

              text-align: left;
              border-radius: 20px;
              height: 45px;
              color: white;
              background-color: white;
              box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
                0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
                0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);

              p {
                font-size: 1em;
              }

              .links-position-icons-container {
                display: flex;
                svg {
                  height: 24px;
                  cursor: pointer;
                }
              }
            }

            .links-error {
              padding: 16px;
              color: red;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
