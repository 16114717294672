.user-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &--headline {
    width: 100%;
    padding-left: 49px;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  &--content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;

    .menu {
      margin-right: 10px;
    }

    .content {
      width: 100%;

      &--add {
        text-align: right;
        svg {
          cursor: pointer;
          height: 40px;
          width: 40px;
        }
      }

      &--overview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-wrapper {
          &.disabled {
            background: #ffb0b0;
          }

          width: 100%;
          max-width: 450px;
          border-radius: 20px;
          padding: 20px 10px;
          box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
            0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
            0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
          background: #f2f4f4;
          margin-bottom: 40px;

          &--item {
            display: flex;
            align-items: center;
            min-height: 30px;

            .title {
              word-break: break-all;
              text-align: right;
              width: 100px;
              margin-right: 10px;
              font-weight: bold;
            }

            .icon-wrapper {
              display: flex;

              > div {
                margin-right: 5px;
                &:last-child {
                  margin-right: 0;
                }
                cursor: pointer;
                &.delete-icon {
                  svg {
                    path {
                      fill: #c40000;
                    }
                  }
                }
                svg {
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
