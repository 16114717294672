.contact-person-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &--headline {
    width: 100%;
    padding-left: 49px;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  &--content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;

    .menu {
      margin-right: 10px;
    }

    .content {
      width: 100%;
      &--add {
        text-align: right;
        svg {
          cursor: pointer;
          height: 40px;
          width: 40px;
        }
      }
      &--overview {
        margin: 0 auto;
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;

        .contact-person-wrapper {
          background-color: #f2f4f4;
          border-radius: 20px;
          box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
            0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
            0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
          padding: 20px 20px;
          margin: 0 auto;
          margin-top: 20px;
          width: 100%;
          max-width: 600px;
          display: flex;
          align-items: center;
          &:first-child {
            margin-top: 0;
          }
          .name {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }

          .image {
            img {
              border-radius: 50%;
              height: 100px;
              width: 100px;
              box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
                0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
                0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
            }
          }

          .function {
            margin-left: 10px;
            display: flex;

            &--trash {
              margin-right: 10px;
              svg {
                path {
                  fill: red;
                }
              }
            }

            > div {
              cursor: pointer;
              svg {
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
