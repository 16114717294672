.funnel-view-wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content-wrapper {
    width: 100%;
    max-width: 700px;

    padding-top: 60px;
    @media (max-width: 480px) {
      padding-top: 0px;
    }

    .funnel-navigation-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      .button-component {
        max-width: 49%;
      }

      @media (max-width: 480px) {
        flex-direction: column;

        .button-component {
          max-width: 100%;
        }
      }
    }
  }
}
