.employee-upload-component-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;

  .headline {
    padding: 10px 0;
  }
  .sub-headline {
    padding: 10px 0;
  }
}
