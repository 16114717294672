.dashboard-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &--headline {
    width: 100%;
    padding-left: 49px;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  &--content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;

    .menu {
      margin-right: 10px;
    }

    .content {
      width: 100%;
      &--overview {
        margin: 0 auto;
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .funnel-box {
          &.standard {
            background: #68bd45;
          }
          border-radius: 20px;
          background: #f2f4f4;
          margin: 10px;
          padding: 10px;
          cursor: pointer;
          box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
            0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
            0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
          height: 120px;
          width: 120px;
          display: flex;
          flex-direction: column;

          .function-wrapper {
            display: flex;
            justify-content: flex-end;

            svg {
              height: 20px;

              &.copy {
                margin-right: 5px;
              }

              &.delete {
                path {
                  fill: #c40000;
                }
              }
            }
          }

          .title {
            word-break: break-all;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            flex-grow: 1;
          }

          .create-date {
            text-align: right;
            font-size: 10px;
          }
        }
      }

      &--add {
        text-align: right;
        svg {
          cursor: pointer;
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
