.link-page {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-header {
    margin-right: auto;
    padding: 20px;
  }
  &-link-container {
    max-width: 600px;
  }
}
