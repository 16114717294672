.contact-person-edit-wrapper {
  .image-file-upload {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--input {
      cursor: pointer;
      background: gray;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
        0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
      img {
        width: 100%;
        border-radius: 50%;
        height: 100%;
      }

      .icon {
        height: 50px;
      }
    }
  }
}
