.register-component-wrapper {
  background: #f2f4f4;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12);
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }

  .input-content-wrapper {
    flex-grow: 1;

    .headline {
      font-size: 2em;
      font-weight: bold;
      color: #464646;
      text-transform: uppercase;
      width: 200px;
    }

    .sub-headline {
      margin-bottom: 20px;
    }

    .input-group-wrapper {
      width: 100%;
      display: flex;

      .input-component {
        width: 100%;
      }

      @media (max-width: 500px) {
        display: initial;
      }
    }

    .legal-info {
      margin: 20px 0;
      line-height: 1.2em;

      a {
        color: black;
        text-decoration: underline;
      }
    }

    .submit-button {
      width: 100%;
      max-width: 200px;

      button {
        min-width: 0px;
      }
    }
  }

  .contact-wrapper {
    width: 400px;
    margin-left: 20px;
    text-align: center;

    @media (max-width: 500px) {
      width: 100%;
      margin-left: 0px;
      margin-top: 20px;
    }

    img {
      margin-bottom: 10px;
      width: 100%;
      box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
        0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
    }

    a {
      color: rgb(0, 0, 0);
      text-decoration: underline;
    }

    .smaller-item {
      margin: 5px 0px;
      font-size: 0.8em;
      line-height: 1.4em;
    }
    .headline {
      margin-bottom: 10px;
    }
  }
}
