.funnel-detail-page {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;

  &--headline {
    width: 100%;
    padding-left: 49px;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  &--content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;

    .menu {
      margin-right: 10px;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;

      .funnel-middle-wrapper,
      .funnel-wrapper {
        flex-grow: 1;
        position: relative;

        .funnel-tree-wrapper {
          position: relative;
        }

        &.funnel-middle-wrapper {
          flex-grow: initial;

          .funnel-step-wrapper {
            height: 50px;
            width: 150px;

            &.no-click {
              cursor: initial;
            }
          }
        }

        .stage-title {
          font-weight: normal;
          font-size: 0.4em;
        }

        .sub-items {
          margin-left: 80px;
        }

        .connection-line {
          position: absolute;
          border-left: 2px solid black;
          border-bottom: 2px solid black;
          height: 50px;
          width: 15px;
        }

        .funnel-step-wrapper {
          z-index: 1;
          height: 100px;
          width: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          text-align: center;
          padding: 10px;
          border-radius: 20px;
          margin: 20px;
          background: #f2f4f4;
          box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
            0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
            0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);

          &:first-child {
            margin-top: 0px !important;
          }

          .index {
            font-size: 2em;
            font-weight: bold;
          }

          .type {
            font-size: 0.8em;
          }

          svg {
            height: 40px;
          }
        }
      }

      .funnel-options-wrapper {
        .funnel-icon-wrapper {
          margin: 20px 0;
          display: flex;
          justify-content: center;
          svg {
            width: 40px;
          }
        }
        .item-separator {
          margin-bottom: 20px;
        }
        &--headline {
          margin-bottom: 20px;
          text-align: center;
        }
        height: fit-content;
        box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.12),
          0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
          0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12);
        padding: 10px;
        border-radius: 20px;
        width: 50%;
        max-width: 400px;
        background: #f2f4f4;

        &--add-icon {
          cursor: pointer;
          margin-top: 40px;
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
          svg {
            height: 20px;
          }
        }

        .element-config-wrapper {
          .headline {
            padding-bottom: 2px;
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid black;
            text-align: center;
            justify-content: center;
            .icon {
              display: flex;
              align-items: center;
              cursor: pointer;
              svg {
                width: 15px;

                path {
                  fill: #c40000;
                }
              }
            }
          }
        }
      }
    }
  }
}
